<template>
  <r-e-dialog title="更换门锁" class="layer-contract-detail" :visible.sync="dialogVisible"
              show-footer top="10vh" @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel" width="1200px">
    <el-form ref="formPublish" label-width="120px" size="small" :model="lockData" :rules="rules">
      <div class="flex">
        <el-form-item label="房源信息">
          <el-input v-model="lockData.apartmentName" style="width: 250px;" disabled />
        </el-form-item>
        <el-form-item label="厂家" prop="iotTag">
          <el-radio-group v-model="lockData.iotTag" style="width: 250px;">
            <el-radio-button :label="0">魔方</el-radio-button>
            <el-radio-button :label="1">力枫</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="门锁编号" prop="lockId">
          <el-input type="text" v-model="lockData.lockId" placeholder="请填写新表号" style="width: 250px;" />
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="维修员姓名" prop="maintainMan">
          <el-input v-model="lockData.maintainMan" placeholder="请填写维修员姓名" style="width: 250px;" />
        </el-form-item>
        <el-form-item label="维修员手机号" prop="maintainPhone">
          <el-input v-model="lockData.maintainPhone" placeholder="请填写维修员手机号" style="width: 250px;" />
        </el-form-item>
        <el-form-item label="旧锁型号" prop="oldMountingsName">
          <el-input v-model="lockData.oldMountingsName" placeholder="请填写旧表型号" style="width: 250px;" />
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="旧锁品牌" prop="oldMountingsBrand">
          <el-input v-model="lockData.oldMountingsBrand" placeholder="请填写旧表品牌" style="width: 250px;" />
        </el-form-item>
        <el-form-item label="新锁型号" prop="mountingsName">
          <el-input v-model="lockData.mountingsName" placeholder="请填写新表型号" style="width: 250px;" />
        </el-form-item>
        <el-form-item label="新锁品牌" prop="mountingsBrand">
          <el-input v-model="lockData.mountingsBrand" placeholder="请填写新表品牌" style="width: 250px;" />
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="换锁前照片" prop="beforeImg" style="width: 100%;">
          <upload-picture-card :uuidList="beforeImgList" :limit="24" @on-success="beforeImgListHandle"
                               @on-remove="beforeImgListHandle" />
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="换锁后照片" prop="afterImg" style="width: 100%;">
          <upload-picture-card :uuidList="afterImgList" :limit="24" @on-success="afterImgHandle"
                               @on-remove="afterImgHandle" />
        </el-form-item>
      </div>
    </el-form>
  </r-e-dialog>
</template>

<script>
  import { MessageInfo, MessageError, MessageSuccess } from "@custom/message";
  import { deviceLock } from "@/api/door-management";
  export default {
    name: "dialog-replace-lock",
    data() {
      return {
        dialogVisible: false,
        lockData: {
          apartmentUuid: null, //房间uuid
          apartmentName: null,  //房间名称
          iotTag: null,
          lockId: null,
          beforeImg: null,  //换表前照片（旧表照片）
          afterImg: null, //换表后照片（新表照片）
          maintainPhone: null,  //维修人员手机号
          maintainMan: null,  //维修人姓名
          oldMountingsName: null, //旧表型号
          oldMountingsBrand: null, //旧表品牌
          mountingsName: null, //新表型号
          mountingsBrand: null, //	新表品牌
        },
        rules: {
          iotTag: [{required: true, message: '请选择厂商', trigger: 'change'}],
          lockId: [{required: true, message: '请输入门锁编号', trigger: 'blur'}],
          beforeImg: [{required: true, message: '请上传换表前照片', trigger: 'change'}],
          afterImg: [{required: true, message: '请上传换表后照片', trigger: 'change'}],
          maintainMan: [{required: true, message: '请填写维修员姓名', trigger: 'blur'}],
          maintainPhone: [{required: true, message: '请填写维修员手机号', trigger: 'blur'}],
          oldMountingsName: [{required: true, message: '请填写旧表型号', trigger: 'blur'}], //旧表型号
          oldMountingsBrand: [{required: true, message: '请填写旧表品牌', trigger: 'blur'}], //旧表品牌
          mountingsName: [{required: true, message: '请填写新表型号', trigger: 'blur'}], //新表型号
          mountingsBrand: [{required: true, message: '请填写新表品牌', trigger: 'blur'}], //	新表品牌
        },
        loadingOptions: {
          lock: true,
          text: "Loading...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        },
        beforeImgList: [],
        afterImgList: [],
      };
    },
    components: {
      uploadPictureCard: () => import("@/components/Upload/upload-picture-card"),
    },
    props: {
      equipmentData: {
        type: Object,
        default: () => ({})
      }
    },
    methods: {
      openDialog() {
        let that = this;
        const { apartmentUuid, apartmentName } = this.equipmentData;
        this.lockData.apartmentUuid = apartmentUuid;
        that.lockData.apartmentName = apartmentName;
        that.dialogVisible = true;
      },

      clickSubmit() {
        let that = this;
        this.$refs["formPublish"].validate((valid) => {
          if (valid) {
            let {
              apartmentUuid, iotTag, lockId, beforeImg, afterImg, maintainPhone, maintainMan, oldMountingsName,
              oldMountingsBrand, mountingsName, mountingsBrand,
            } = that.lockData;
            let data = {
              apartmentUuid, iotTag, lockId, beforeImg, afterImg, maintainPhone, maintainMan, oldMountingsName,
              oldMountingsBrand, mountingsName, mountingsBrand,
            };
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            deviceLock(data).then(res=>{
              MessageSuccess("更换成功");
              that.clickCancel();
            }).finally(() => loading.close());
          }
        });
      },

      clickCancel() {
        this.dialogVisible = false;
        // this.$emit('getHydropower', res.info);
        this.lockData = {
          apartmentUuid: null, //房间uuid
          apartmentName: null,  //房间名称
          iotTag: null,
          lockId: null,
          beforeImg: null,  //换表前照片（旧表照片）
          afterImg: null, //换表后照片（新表照片）
          maintainPhone: null,  //维修人员手机号
          maintainMan: null,  //维修人姓名
          oldMountingsName: null, //旧表型号
          oldMountingsBrand: null, //旧表品牌
          mountingsName: null, //新表型号
          mountingsBrand:null, //	新表品牌
        };
      },

      afterImgHandle({fileList}) {
        this.afterImgList = fileList.map(item => {
          let {name, response} = item;
          let resOk = response && typeof (response) !== undefined;
          if (resOk) {
            let {returnObject: {info: {uuid}}} = response;
            return uuid;
          } else return name;
        });
        this.lockData.afterImg = this.afterImgList.join(";");
        this.$refs["formPublish"].validateField("afterImg");
      },

      beforeImgListHandle({fileList}) {
        this.beforeImgList = fileList.map(item => {
          let {name, response} = item;
          let resOk = response && typeof (response) !== undefined;
          if (resOk) {
            let {returnObject: {info: {uuid}}} = response;
            return uuid;
          } else return name;
        });
        this.lockData.beforeImg = this.beforeImgList.join(";");
        this.$refs["formPublish"].validateField("beforeImg");
      },
    },
  }
</script>

<style scoped>

</style>